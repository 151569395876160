<template>
  <container ref="container" @error="init">
    <!-- 工作 -->
    <publicSection class="job" :header="require('@/assets/image/job/job.png')">
      <!-- 工作-列表 -->
      <div class="job-list">
        <!-- 工作-元素 -->
        <div class="job-item" v-html="detail.content"></div>
      </div>
    </publicSection>
  </container>
</template>

<script>
import publicSection from "@/components/publicSection.vue";

export default {
  name: "job",
  components: {
    publicSection,
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        // 获取数据
        const result = await this.$axios("/api/mainstation/findWork");

        // 设置数据
        this.detail = result.data;

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 工作
  .job {
    width: 1200px;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 30px;

    // 工作-顶部
    &-header {
      margin: auto;
      display: block;
      user-select: none;
    }

    // 工作-列表
    &-list {
      margin-top: 40px;
    }

    // 工作-元素
    &-item {
      padding: 20px;
      border: 1px solid #e6e6e6;
      color: #a5a5a5;
      background-color: #f8f8f8;
      font-size: 16px;
      margin-bottom: 40px;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 工作
  .job {
    width: 100%;
    padding-top: rpx(50);
    padding-bottom: rpx(30);

    // 工作-顶部
    &-header {
      margin: auto;
      display: block;
      user-select: none;
    }

    // 工作-列表
    &-list {
      // margin-top: r;
    }

    // 工作-元素
    &-item {
      padding: rpx(40);
      font-size: rpx(32);
      margin-bottom: rpx(40);
    }
  }
}
</style>
